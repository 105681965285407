.sections {
  &__container {
    position: relative;
    display: flex;
    align-items: flex-start;

    #courses {
      position: relative;
      transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

#offersIntro.section {
  .section {
    &__header {
      color: $mainOrangeLight;
      background-image: linear-gradient(90deg, $mainOrange, $sfzOrange);
      background-color: $mainOrange;
      strong {
        color: inherit !important;
      }
    }
  }
}

.section {
  > * {
    padding-top: var(--spacing-5xl);
    padding-bottom: var(--spacing-5xl);
  }

  &-item,
  &-item__one {
    margin-bottom: 1em;
    &-header {
      &__title {
        font-weight: 700;
        font-size: 1.7em;
        padding-top: 1.5em;
        padding-bottom: 0.5em;
        color: #fc9803;
      }
      &__description {
        margin-top: 1em;
        margin-bottom: 1em;
      }
    }
    &-body {
      padding-top: 1em;
    }
  }

  &__description {
    margin: 1em 0;
  }
  &--h2 {
    font-size: 2.5em;
    font-weight: 700;
    padding-top: 0.5em;
    padding-bottom: 0.25em;
    margin-bottom: 0.5em;
  }
  &--h3 {
    font-weight: 700;
    font-size: 1.7em;
    padding-top: 0.8em;
    padding-bottom: 0.4em;
    color: $mainOrange;
  }
  &__header {
    display: flex;
    > * {
      margin-top: auto;
      margin-bottom: auto;
    }
    background-color: $mainBlueLight;
    min-height: calc(100vh - 66px);
    // > * {
    //   max-width: 800px;
    // }

    &--title {
      margin: 0;
      margin-top: 1em;
      color: #3f51b5;
      margin-left: 1em;
      font-weight: 700;
      font-size: 2.5em;
      text-align: center;
      margin-bottom: 1.5em;
    }
    &--text {
      padding-top: var(--spacing-xs);
      padding-bottom: var(--spacing-xs);
    }
    &--description {
      padding: 0 1em;
      max-width: 800px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 1.1em;
      font-weight: 600;
    }
  }
  &__body {
    // background-color: #f0f3f7;
    &--description {
      margin: 1em 0;
    }
    .feature {
      &__body {
        margin-top: 16px;
        padding: 10px;
        [class^='link__btn'] {
          margin-top: 1rem;
          padding: 0 !important;
        }
        a[class^='link__btn'] {
          padding: 10px 15px !important;
        }
      }
      &__img {
        padding: 0;
        overflow: hidden;
        border-radius: 1em !important;
        // box-shadow: 0 0.8em 2em #1f1f1f40;
        // border: 8px solid darken($color: $backgroundLight, $amount: 8%);
        &--image {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &--heading {
        font-size: 2em;
        margin-bottom: 1em;
        line-height: 1;
        strong {
          color: $mainOrange;
          font-size: 0.7em !important;
        }
      }
      // p {
      //   font-size: 1.1em;
      // }
    }
  }
  &__footer {
    padding: 4em 0;
    text-align: center;
    &--container {
      max-width: 40em;
    }
    p {
      margin: 0;
      padding: 1.5em;
      font-size: 1.2em;
      font-weight: 700;
      margin-top: 1em;
      margin-bottom: 1em;
    }
    &--link {
      margin-top: 3em;
      text-align: center;
      .btn {
        padding: 1.5em !important;
        border-radius: 30px !important;
      }
    }
    .btn {
      padding: 1.5em !important;
      border-radius: 30px !important;
    }
  }

  &-dark {
    color: #e9e7e3;
    background-color: #fa8405;
    .section__header {
      padding-top: 5px;
      &--title {
        color: white;
      }
      p {
        font-size: 1.05em;
        font-weight: 500;
      }
    }
  }
}

.course {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  align-items: center;
  &-item,
  &-item-one {
    margin-bottom: 1em;
  }
  &__body {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1em;
    // justify-content: center;
  }
}

// .travel {
//   &__bottom {
//     color: white;
//     background-color: #3f51b5;
//   }
// }

@media (min-width: 768px) {
  .section {
    #guideList.card--grid,
    #childrenList.card--grid {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 2fr;
    }

    &__body {
      .feature {
        display: flex;
        column-gap: 50px;
        justify-content: space-evenly;
        &__img {
          width: 400px;
        }
        &__body {
          margin: 0;
          max-width: 650px;
        }
      }
    }
    &__travel {
      .travelbox {
        max-width: 800px;
        display: flex;
        &__body {
          display: flex;
          flex-direction: column;
          margin-top: 0;
          margin-left: 10px;
          p {
            margin: 0;
            margin-top: 10px;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .section {
    #guideList.card--grid,
    #childrenList.card--grid {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 2fr;
    }
    &__body {
      &--text {
        order: 2;
      }
    }
  }
  #coursesSection {
    .sections {
      &__container {
        display: grid;
        column-gap: 2em;
        align-items: start;
        grid-template-columns: 275px 1fr;
      }
    }
  }
}
