.main-header {
  display: flex;
  padding-top: 100px;
  position: relative;
  padding-bottom: 14px;
}

.page-header {
  background-color: #f4eee1;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  .scroll-down {
    &-box {
      bottom: auto;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    max-width: 1140px;
    margin: auto;
    padding: 0 2em;
  }

  &__body {
    margin-bottom: 1em;
    &--title {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
      font-weight: 700;
      color: $primary;
      text-transform: uppercase;
    }
    &--subtitle {
      font-weight: 700;
      font-size: 1.5em;
      display: inline-block;
      padding-bottom: 0.2em;
      margin: 0;
      border-bottom: 4px solid #fcaf03;
    }
    &--description {
      max-width: 450px;
      font-size: 1.1em;
    }
    #HeaderScrollBtn {
      color: white;
      padding: 1em 2em;
      font-weight: 700;
      border-radius: 50px;
      display: inline-block;
      text-transform: uppercase;
      background-image: linear-gradient(90deg, $mainOrange, $sfzOrange);
    }
  }

  &__img {
    display: flex;
    img {
      width: 100%;
      margin: auto;
    }
  }
}

.blendmode-header {
  color: white;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &__img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }

    .img__blendmode {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      background-color: $primary;
      background: linear-gradient(
        0deg,
        rgba(20, 9, 65, 0.8),
        rgba(15, 7, 47, 0.6),
        rgba(20, 9, 65, 0.8)
      );
    }
  }

  &__body {
    &--title {
      &-h1 {
        color: white;
        font-size: 3.5em;
        font-family: 'Edu SA Beginner';
        font-weight: 700;
        margin: 25px;
        margin-bottom: 0;
      }
    }
    &--subtile {
      font-size: 1.5em;
      font-style: italic;
      font-weight: 600;
    }
    &--description {
      &-text {
        color: rgb(219, 219, 219);
        font-size: 1em;
        font-style: italic;
      }
    }
  }
}
