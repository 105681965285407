@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Didact+Gothic&family=Edu+NSW+ACT+Foundation:wght@400;500;600;700&family=Edu+SA+Beginner:wght@400;500;600;700&family=Quicksand:wght@300;400;500;600;700&display=swap');

:root {
  --font-family-sans-serif: Quicksand, 'Didact Gothic', 'Helvetica Neue',
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  --font-family-serif: fa-livory, 'Georgia', 'Times New Roman', 'Times', serif;
  --font-family-monospace: 'Menlo', 'Monaco', 'Consolas', 'Courier New',
    monospace;
  --font-family-base: var(--font-family-sans-serif);
  --font-size-root: 14px;
  --font-size-root-tablet: 16px;
  --spacing-base: 1em;
  --spacing-0: 0;
  --spacing-1: calc(var(--spacing-base) * 1 / 16);
  --spacing-1px: 1px;
  --spacing-7xs: calc(var(--spacing-base) * 1 / 16);
  --spacing-6xs: calc(var(--spacing-base) * 2 / 16);
  --spacing-5xs: calc(var(--spacing-base) * 3 / 16);
  --spacing-4xs: calc(var(--spacing-base) * 4 / 16);
  --spacing-3xs: calc(var(--spacing-base) * 6 / 16);
  --spacing-2xs: calc(var(--spacing-base) * 8 / 16);
  --spacing-xs: calc(var(--spacing-base) * 10 / 16);
  --spacing-sm: calc(var(--spacing-base) * 12 / 16);
  --spacing-md: calc(var(--spacing-base) * 1);
  --spacing-lg: calc(var(--spacing-base) * 1.25);
  --spacing-xl: calc(var(--spacing-base) * 1.5);
  --spacing-2xl: calc(var(--spacing-base) * 2);
  --spacing-3xl: calc(var(--spacing-base) * 2.5);
  --spacing-4xl: calc(var(--spacing-base) * 3);
  --spacing-5xl: calc(var(--spacing-base) * 4);
  --spacing-6xl: calc(var(--spacing-base) * 5);
  --spacing-7xl: calc(var(--spacing-base) * 6);
  --spacing-8xl: calc(var(--spacing-base) * 8);
  --spacing-9xl: calc(var(--spacing-base) * 10);
  --spacing-10xl: calc(var(--spacing-base) * 12);
  --spacing-11xl: calc(var(--spacing-base) * 16);
  --spacing-12xl: calc(var(--spacing-base) * 20);
}

html {
  position: relative;
}

body,
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-family-base);
  background-color: $bodyBg;
  line-height: 1.5;
  font-weight: 500;
  font-size: 18px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
  // font-family: Nunito, 'Edu SA Beginner', 'Edu NSW ACT Foundation', 'Didact Gothic';
}

.hr--divider {
  color: rgba(0, 0, 0, 0.3);
  margin: 5.5em 0 !important;
}

p {
  margin: 0;
  margin-bottom: 15px;
}

a {
  text-decoration: none;
  outline: none;
}

.inline-link {
  color: $primary;
  text-decoration: none;
  font-weight: 600;
  outline: none;
  &:hover {
    color: darken($primary, 10);
  }
  &:visited {
    color: #c71ca1;
    &:hover {
      color: darken(#c71ca1, 10);
    }
  }
}

.bgc-corper {
  background-color: #e9edf0;
}

.bgc-corper-white {
  background-color: #ffffff;
}

.bg-gradient-gray {
  background-color: #fff;
  background-image: linear-gradient(
    90deg,
    #f1f1f1,
    transparent 30% 70%,
    #f1f1f1
  );
  background-repeat: no-repeat;
  background-size: contain;
}

.main-wrapper,
.wrapper {
  width: 100%;
  height: 100%;
}

.fa-user-circle {
  margin-right: 0 !important;
  font-size: 1.5em;
}

.fa-ellipsis-v {
  margin-left: 5px !important;
}

.sfz-bg-color {
  background-color: var(--orange-dark);
}

.line-t-white,
.line-y-white,
.line-white {
  border: none;
  border-top: 1px solid white;
}

.line-r-white,
.line-x-white,
.line-white {
  border: none;
  border-right: 1px solid white;
}

.line-b-white,
.line-y-white,
.line-white {
  border: none;
  border-bottom: 1px solid white;
}

.line-l-white,
.line-x-white,
.line-white {
  border: none;
  border-left: 1px solid white;
}

.line-t-lightgray,
.line-y-lightgray,
.line-lightgray {
  border: none;
  border-top: 1px solid #ddd;
}

.line-r-lightgray,
.line-x-lightgray,
.line-lightgray {
  border: none;
  border-right: 1px solid #ddd;
}

.line-b-lightgray,
.line-y-lightgray,
.line-lightgray {
  border: none;
  border-bottom: 1px solid #ddd;
}

.line-l-lightgray,
.line-x-lightgray,
.line-lightgray {
  border: none;
  border-left: 1px solid #ddd;
}

.line-t-mediumgray,
.line-y-mediumgray,
.line-mediumgray {
  border: none;
  border-top: 1px solid #ccc;
}

.line-r-mediumgray,
.line-x-mediumgray,
.line-mediumgray {
  border: none;
  border-right: 1px solid #ccc;
}

.line-b-mediumgray,
.line-y-mediumgray,
.line-mediumgray {
  border: none;
  border-bottom: 1px solid #ccc;
}

.line-l-mediumgray,
.line-x-mediumgray,
.line-mediumgray {
  border: none;
  border-left: 1px solid #ccc;
}

.bdr-r-tl-1,
.bdr-r-t-1,
.bdr-r-1 {
  border-top-left-radius: 5px !important;
}

.bdr-r-tr-1,
.bdr-r-t-1,
.bdr-r-1 {
  border-top-right-radius: 5px !important;
}

.bdr-r-bl-1,
.bdr-r-b-1,
.bdr-r-1 {
  border-bottom-left-radius: 5px !important;
}

.bdr-r-br-1,
.bdr-r-b-1,
.bdr-r-1 {
  border-bottom-right-radius: 5px !important;
}

.bdr-r-tl-2,
.bdr-r-t-2,
.bdr-r-2 {
  border-top-left-radius: 10px !important;
}

.bdr-r-tr-2,
.bdr-r-t-2,
.bdr-r-2 {
  border-top-right-radius: 10px !important;
}

.bdr-r-br-2,
.bdr-r-b-2,
.bdr-r-2 {
  border-bottom-right-radius: 10px !important;
}

.bdr-r-bl-2,
.bdr-r-b-2,
.bdr-r-2 {
  border-bottom-left-radius: 10px !important;
}

.bdr-r-tl-3,
.bdr-r-t-3,
.bdr-r-3 {
  border-top-left-radius: 15px !important;
}

.bdr-r-tr-3,
.bdr-r-t-3,
.bdr-r-3 {
  border-top-right-radius: 15px !important;
}

.bdr-r-br-3,
.bdr-r-b-3,
.bdr-r-3 {
  border-bottom-right-radius: 15px !important;
}

.bdr-r-bl-3,
.bdr-r-b-3,
.bdr-r-3 {
  border-bottom-left-radius: 15px !important;
}

.bdr-r\(50\%\) {
  border-radius: 50%;
}

.csr-pointer {
  cursor: pointer;
}

.transition-\(0\) {
  transition: 1s cubic-bezier(0.53, 1.15, 0.85, 0.25);
}

.transition-\(1\) {
  transition: 0.7s cubic-bezier(0.26, 0.63, 0.44, -0.42);
}

.transition-\(2\) {
  transition: 0.6s cubic-bezier(0.85, 0, 0.28, 1.22);
}

.transition-\(3\) {
  transition: 0.6s cubic-bezier(0.62, 0.14, 0.44, 0.94);
}

.transition-\(4\) {
  transition: 0.6s cubic-bezier(0.63, 0, 0.15, 1);
}

.transition-\(5\) {
  transition: 0.7s cubic-bezier(0, 0.48, 1, 0.54);
}

.flex-basis\(10\) {
  flex-basis: 10%;
}

.flex-basis\(15\) {
  flex-basis: 15%;
}

.flex-basis\(20\) {
  flex-basis: 20%;
}

.flex-basis\(25\) {
  flex-basis: 25%;
}

.flex-basis\(30\) {
  flex-basis: 30%;
}

.flex-basis\(35\) {
  flex-basis: 35%;
}

.flex-basis\(40\) {
  flex-basis: 40%;
}

.flex-basis\(45\) {
  flex-basis: 45%;
}

.flex-basis\(50\) {
  flex-basis: 50%;
}

.flex-basis\(55\) {
  flex-basis: 55%;
}

.flex-basis\(60\) {
  flex-basis: 60%;
}

.flex-basis\(65\) {
  flex-basis: 65%;
}

.flex-basis\(70\) {
  flex-basis: 70%;
}

.flex-basis\(75\) {
  flex-basis: 75%;
}

.flex-basis\(80\) {
  flex-basis: 80%;
}

.flex-basis\(85\) {
  flex-basis: 85%;
}

.flex-basis\(90\) {
  flex-basis: 90%;
}

.flex-basis\(95\) {
  flex-basis: 95%;
}

.gtc\(1\) {
  grid-template-columns: repeat(1, 1fr);
}

.gtc\(2\) {
  grid-template-columns: repeat(2, 1fr);
}

.gtc\(3\) {
  grid-template-columns: repeat(3, 1fr);
}

.gtc\(4\) {
  grid-template-columns: repeat(4, 1fr);
}

.gtc\(5\) {
  grid-template-columns: repeat(5, 1fr);
}

.gtc\(6\) {
  grid-template-columns: repeat(6, 1fr);
}

.gtc\(7\) {
  grid-template-columns: repeat(7, 1fr);
}

.gtc\(8\) {
  grid-template-columns: repeat(8, 1fr);
}

.gcg\(1\),
.gg\(1\) {
  grid-column-gap: 0.1rem;
}

.gcg\(2\),
.gg\(2\) {
  grid-column-gap: 0.2rem;
}

.gcg\(3\),
.gg\(3\) {
  grid-column-gap: 0.3rem;
}

.gcg\(4\),
.gg\(4\) {
  grid-column-gap: 0.4rem;
}

.gcg\(5\),
.gg\(5\) {
  grid-column-gap: 0.5rem;
}

.gcg\(6\),
.gg\(6\) {
  grid-column-gap: 0.6rem;
}

.gcg\(7\),
.gg\(7\) {
  grid-column-gap: 0.7rem;
}

.gcg\(8\),
.gg\(8\) {
  grid-column-gap: 0.8rem;
}

.gcg\(9\),
.gg\(9\) {
  grid-column-gap: 0.9rem;
}

.gcg\(10\),
.gg\(10\) {
  grid-column-gap: 1rem;
}

.gcg\(11\),
.gg\(11\) {
  grid-column-gap: 1.1rem;
}

.gcg\(12\),
.gg\(12\) {
  grid-column-gap: 1.2rem;
}

.gcg\(13\),
.gg\(13\) {
  grid-column-gap: 1.3rem;
}

.gcg\(14\),
.gg\(14\) {
  grid-column-gap: 1.4rem;
}

.gcg\(15\),
.gg\(15\) {
  grid-column-gap: 1.5rem;
}

.gcg\(20\),
.gg\(20\) {
  grid-column-gap: 2rem;
}

.gcg\(25\),
.gg\(25\) {
  grid-column-gap: 2.5rem;
}

.gcg\(30\),
.gg\(30\) {
  grid-column-gap: 3rem;
}

.grg\(1\),
.gg\(1\) {
  grid-row-gap: 0.1rem;
}

.grg\(2\),
.gg\(2\) {
  grid-row-gap: 0.2rem;
}

.grg\(3\),
.gg\(3\) {
  grid-row-gap: 0.3rem;
}

.grg\(4\),
.gg\(4\) {
  grid-row-gap: 0.4rem;
}

.grg\(5\),
.gg\(5\) {
  grid-row-gap: 0.5rem;
}

.grg\(6\),
.gg\(6\) {
  grid-row-gap: 0.6rem;
}

.grg\(7\),
.gg\(7\) {
  grid-row-gap: 0.7rem;
}

.grg\(8\),
.gg\(8\) {
  grid-row-gap: 0.8rem;
}

.grg\(9\),
.gg\(9\) {
  grid-row-gap: 0.9rem;
}

.grg\(10\),
.gg\(10\) {
  grid-row-gap: 1rem;
}

.grg\(11\),
.gg\(11\) {
  grid-row-gap: 1.1rem;
}

.grg\(12\),
.gg\(12\) {
  grid-row-gap: 1.2rem;
}

.grg\(13\),
.gg\(13\) {
  grid-row-gap: 1.3rem;
}

.grg\(14\),
.gg\(14\) {
  grid-row-gap: 1.4rem;
}

.grg\(15\),
.gg\(15\) {
  grid-row-gap: 1.5rem;
}

.grg\(20\),
.gg\(20\) {
  grid-row-gap: 2rem;
}

.grg\(25\),
.gg\(25\) {
  grid-row-gap: 2.5rem;
}

.grg\(30\),
.gg\(30\) {
  grid-row-gap: 3rem;
}

.gaf-c {
  grid-auto-flow: column;
}

.ls-none {
  list-style: none;
}

*:focus {
  outline: none;
}

.replaced-svg {
  width: 1.5rem;
  fill: var(--blue);
}

.shadow-lg-r {
  box-shadow: 0.5px 0 0.03px rgba(0, 0, 0, 0.175) !important;
}

.shadow-lg-tp {
  box-shadow: 0 -0.5px 0.03px rgba(0, 0, 0, 0.175) !important;
}

.shadow {
  box-shadow: 0 0.5px 0.5rem rgba(0, 0, 0, 0.3), 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.shadow-bottom {
  box-shadow: 0 0.3rem 0.3rem 0 rgba(20, 20, 20, 0.03),
    0 0.6rem 1rem 0 rgba(20, 20, 20, 0.14);
}

.shadow-drop {
  box-shadow: 0px 6px 0.5rem rgba(0, 0, 0, 0.2),
    0px 6px 0.5rem rgba(255, 167, 151, 0.502);
}

.shadow-light {
  box-shadow: 0 0 0.4rem rgba(0, 0, 0, 0.125);
}

.light-gradian {
  background-color: #f8fdff;
  background-image: linear-gradient(180deg, #f0f2f8, transparent, #fff9f6);
}

.white-gradian {
  background-image: linear-gradient(180deg, transparent, #ffffff, #fff);
}

.animated--grow-in {
  -webkit-animation-name: growIn;
  animation-name: growIn;
  -webkit-animation-duration: 200ms;
  animation-duration: 200ms;
  -webkit-animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
  animation-timing-function: transform cubic-bezier(0.18, 1.25, 0.4, 1),
    opacity cubic-bezier(0, 1, 0.4, 1);
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

.intersecting {
  font-weight: 700 !important;
}

#MainMenuButton {
  display: none;
}

.menuButton.dashes {
  width: 30px;
  color: #858796;
}
.menuButton.dashes [class*='dash-'] {
  width: 100%;
  height: 0.15rem;
  position: absolute;
  background-color: #61676f;
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.menuButton.dashes:hover {
  color: #6095ff;
}
.menuButton.dashes:hover [class*='dash-'] {
  background-color: #6095ff;
}
.menuButton.dashes .dash-1 {
  top: -20px;
}
.menuButton.dashes .dash-2 {
  top: -10px;
}
.menuButton.dashes .dash-3 {
  top: 0;
}

.closed-dashes {
  position: relative;
}
.closed-dashes .dash-1,
.closed-dashes .dash-3 {
  position: absolute;
}
.closed-dashes .dash-1 {
  top: -10px !important;
  transform: rotate(45deg);
}
.closed-dashes .dash-2,
.closed-dashes .dash-3 {
  top: -10px !important;
  transform: rotate(-45deg) !important;
}

@media only screen and (min-width: 768px) {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
}

.m {
  &--1 {
    margin: 0.5em;
  }
  &--2 {
    margin: 0.75em;
  }
  &--3 {
    margin: 1em;
  }
  &--4 {
    margin: 1.25em;
  }
  &--5 {
    margin: 1.5em;
  }
  &--6 {
    margin: 2em;
  }
  &--7 {
    margin: 2.5em;
  }
  &--8 {
    margin: 3em;
  }
  &--9 {
    margin: 4em;
  }
  &--10 {
    margin: 5em;
  }

  &y {
    &--1 {
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
    &--2 {
      margin-top: 0.75em;
      margin-bottom: 0.75em;
    }
    &--3 {
      margin-top: 1em;
      margin-bottom: 1em;
    }
    &--4 {
      margin-top: 1.25em;
      margin-bottom: 1.25em;
    }
    &--5 {
      margin-top: 1.5em;
      margin-bottom: 1.5em;
    }
    &--6 {
      margin-top: 2em;
      margin-bottom: 2em;
    }
    &--7 {
      margin-top: 2.5em;
      margin-bottom: 2.5em;
    }
    &--8 {
      margin-top: 3em;
      margin-bottom: 3em;
    }
    &--9 {
      margin-top: 4em;
      margin-bottom: 4em;
    }
    &--10 {
      margin-top: 5em;
      margin-bottom: 5em;
    }
  }

  &t {
    &--1 {
      margin-top: 0.5em;
    }
    &--2 {
      margin-top: 0.75em;
    }
    &--3 {
      margin-top: 1em;
    }
    &--4 {
      margin-top: 1.25em;
    }
    &--5 {
      margin-top: 1.5em;
    }
    &--6 {
      margin-top: 2em;
    }
    &--7 {
      margin-top: 2.5em;
    }
    &--8 {
      margin-top: 3em;
    }
    &--9 {
      margin-top: 4em;
    }
    &--10 {
      margin-top: 5em;
    }
  }

  &b {
    &--1 {
      margin-bottom: 0.5em;
    }
    &--2 {
      margin-bottom: 0.75em;
    }
    &--3 {
      margin-bottom: 1em;
    }
    &--4 {
      margin-bottom: 1.25em;
    }
    &--5 {
      margin-bottom: 1.5em;
    }
    &--6 {
      margin-bottom: 2em;
    }
    &--7 {
      margin-bottom: 2.5em;
    }
    &--8 {
      margin-bottom: 3em;
    }
    &--9 {
      margin-bottom: 4em;
    }
    &--10 {
      margin-bottom: 5em;
    }
  }

  &x {
    &--1 {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
    &--2 {
      margin-left: 0.75em;
      margin-right: 0.75em;
    }
    &--3 {
      margin-left: 1em;
      margin-right: 1em;
    }
    &--4 {
      margin-left: 1.25em;
      margin-right: 1.25em;
    }
    &--5 {
      margin-left: 1.5em;
      margin-right: 1.5em;
    }
    &--6 {
      margin-left: 2em;
      margin-right: 2em;
    }
    &--7 {
      margin-left: 2.5em;
      margin-right: 2.5em;
    }
    &--8 {
      margin-left: 3em;
      margin-right: 3em;
    }
    &--9 {
      margin-left: 4em;
      margin-right: 4em;
    }
    &--10 {
      margin-left: 5em;
      margin-right: 5em;
    }
  }

  &e {
    &--1 {
      margin-right: 0.5em;
    }
    &--2 {
      margin-right: 0.75em;
    }
    &--3 {
      margin-right: 1em;
    }
    &--4 {
      margin-right: 1.25em;
    }
    &--5 {
      margin-right: 1.5em;
    }
    &--6 {
      margin-right: 2em;
    }
    &--7 {
      margin-right: 2.5em;
    }
    &--8 {
      margin-right: 3em;
    }
    &--9 {
      margin-right: 4em;
    }
    &--10 {
      margin-right: 5em;
    }
  }

  &s {
    &--1 {
      margin-left: 0.5em;
    }
    &--2 {
      margin-left: 0.75em;
    }
    &--3 {
      margin-left: 1em;
    }
    &--4 {
      margin-left: 1.25em;
    }
    &--5 {
      margin-left: 1.5em;
    }
    &--6 {
      margin-left: 2em;
    }
    &--7 {
      margin-left: 2.5em;
    }
    &--8 {
      margin-left: 3em;
    }
    &--9 {
      margin-left: 4em;
    }
    &--10 {
      margin-left: 5em;
    }
  }
}

.p {
  &--1 {
    padding: 0.5em;
  }
  &--2 {
    padding: 0.75em;
  }
  &--3 {
    padding: 1em;
  }
  &--4 {
    padding: 1.25em;
  }
  &--5 {
    padding: 1.5em;
  }
  &--6 {
    padding: 2em;
  }
  &--7 {
    padding: 2.5em;
  }
  &--8 {
    padding: 3em;
  }
  &--9 {
    padding: 4em;
  }
  &--10 {
    padding: 5em;
  }

  &y {
    &--1 {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
    &--2 {
      padding-top: 0.75em;
      padding-bottom: 0.75em;
    }
    &--3 {
      padding-top: 1em;
      padding-bottom: 1em;
    }
    &--4 {
      padding-top: 1.25em;
      padding-bottom: 1.25em;
    }
    &--5 {
      padding-top: 1.5em;
      padding-bottom: 1.5em;
    }
    &--6 {
      padding-top: 2em;
      padding-bottom: 2em;
    }
    &--7 {
      padding-top: 2.5em;
      padding-bottom: 2.5em;
    }
    &--8 {
      padding-top: 3em;
      padding-bottom: 3em;
    }
    &--9 {
      padding-top: 4em;
      padding-bottom: 4em;
    }
    &--10 {
      padding-top: 5em;
      padding-bottom: 5em;
    }
  }

  &x {
    &--1 {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
    &--2 {
      padding-left: 0.75em;
      padding-right: 0.75em;
    }
    &--3 {
      padding-left: 1em;
      padding-right: 1em;
    }
    &--4 {
      padding-left: 1.25em;
      padding-right: 1.25em;
    }
    &--5 {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }
    &--6 {
      padding-left: 2em;
      padding-right: 2em;
    }
    &--7 {
      padding-left: 2.5em;
      padding-right: 2.5em;
    }
    &--8 {
      padding-left: 3em;
      padding-right: 3em;
    }
    &--9 {
      padding-left: 4em;
      padding-right: 4em;
    }
    &--10 {
      padding-left: 5em;
      padding-right: 5em;
    }
  }

  &t {
    &--1 {
      padding-top: 0.5em;
    }
    &--2 {
      padding-top: 0.75em;
    }
    &--3 {
      padding-top: 1em;
    }
    &--4 {
      padding-top: 1.25em;
    }
    &--5 {
      padding-top: 1.5em;
    }
    &--6 {
      padding-top: 2em;
    }
    &--7 {
      padding-top: 2.5em;
    }
    &--8 {
      padding-top: 3em;
    }
    &--9 {
      padding-top: 4em;
    }
    &--10 {
      padding-top: 5em;
    }
  }

  &s {
    &--1 {
      padding-left: 0.5em;
    }
    &--2 {
      padding-left: 0.75em;
    }
    &--3 {
      padding-left: 1em;
    }
    &--4 {
      padding-left: 1.25em;
    }
    &--5 {
      padding-left: 1.5em;
    }
    &--6 {
      padding-left: 2em;
    }
    &--7 {
      padding-left: 2.5em;
    }
    &--8 {
      padding-left: 3em;
    }
    &--9 {
      padding-left: 4em;
    }
    &--10 {
      padding-left: 5em;
    }
  }

  &b {
    &--1 {
      padding-bottom: 0.5em;
    }
    &--2 {
      padding-bottom: 0.75em;
    }
    &--3 {
      padding-bottom: 1em;
    }
    &--4 {
      padding-bottom: 1.25em;
    }
    &--5 {
      padding-bottom: 1.5em;
    }
    &--6 {
      padding-bottom: 2em;
    }
    &--7 {
      padding-bottom: 2.5em;
    }
    &--8 {
      padding-bottom: 3em;
    }
    &--9 {
      padding-bottom: 4em;
    }
    &--10 {
      padding-bottom: 5em;
    }
  }

  &e {
    &--1 {
      padding-right: 0.5em;
    }
    &--2 {
      padding-right: 0.75em;
    }
    &--3 {
      padding-right: 1em;
    }
    &--4 {
      padding-right: 1.25em;
    }
    &--5 {
      padding-right: 1.5em;
    }
    &--6 {
      padding-right: 2em;
    }
    &--7 {
      padding-right: 2.5em;
    }
    &--8 {
      padding-right: 3em;
    }
    &--9 {
      padding-right: 4em;
    }
    &--10 {
      padding-right: 5em;
    }
  }
}

.sfz {
  &--bg {
    &-gradient {
      &-orange {
        color: $mainOrangeLight;
        background-image: linear-gradient(90deg, $mainOrange, $sfzOrange);
        background-color: $mainOrange !important;
        strong {
          color: inherit !important;
        }
      }
      &-blue {
        color: $mainBlueLight;
        background-image: linear-gradient(90deg, $mainBlue, $primary);
        background-color: $mainBlue !important;
        strong {
          color: inherit !important;
        }
      }
    }
  }
}

.img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}