.card__container {
  max-width: 360px;
  margin-bottom: 2em !important;
}

.simple-card {
  border: none;
  display: flex;
  position: relative;
  word-wrap: break-word;
  flex-direction: column;
  background-color: white;
  background-clip: border-box;
  justify-content: space-between;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 3px rgba(8, 28, 70, 0.25);
  &:hover {
    box-shadow: 0 1px 12px rgba(8, 82, 231, 0.35);
  }
  &__body {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  &__title {
    text-align: center;
    line-height: 1;
    padding: 1em;
    font-weight: 700;
    font-size: 1.25em;
    border-bottom: 1px solid lighten($color: $gray, $amount: 43);
  }
  &__ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.9em;
    font-weight: 600;
  }
  &__li {
    display: flex;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;
    svg {
      margin-top: 0;
    }
    span {
      margin-left: 0.5em;
      flex: 11;
    }
  }
  &__link {
    padding: 0.8em;
    font-weight: 600;
    color: #0b51b9;
    display: flex;
    transition: 0.3s ease;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-top: 1px solid lighten($color: $gray, $amount: 43);
    &:hover {
      color: $mainOrange;
    }
  }
  &__description {
    text-align: center;
  }
  &--text {
    font-size: 1.1em;
    font-weight: 600;
    text-align: center;
    background-color: #e9ecef;
  }
}

.card {
  &-one {
    border: none;
    display: flex;
    position: relative;
    word-wrap: break-word;
    background-color: white;
    background-clip: border-box;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 2px 3px rgba(8, 28, 70, 0.25);
    &:hover {
      box-shadow: 0 1px 12px rgba(8, 82, 231, 0.35);
    }
    &__header {
      flex-basis: 30%;
    }
    &__body {
      flex: 1;
      display: flex;
      padding: 0.7em 0.5em;
      padding-left: 1em;
      flex-direction: column;
      justify-content: space-between;
      &--title {
        padding: 0.4em 0;
        font-weight: 700;
        font-size: 1em;
      }
      &--description {
        font-size: .85em;
      }
    }
    &__footer {
      &--link {
        padding: 0.4em;
        font-size: .8em;
        font-weight: 600;
        border-top: 1px solid;
        border-bottom: 1px solid;
        // border-left: 1px solid;
        &:hover {
          color: $mainOrange;
        }
      }
    }
    &--text {
      font-size: 1.1em;
      font-weight: 600;
      text-align: center;
      background-color: #e9ecef;
    }
    &__ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 0.9em;
      font-weight: 600;
    }
    &__li {
      display: flex;
      align-items: center;
      padding-left: 1em;
      padding-right: 1em;
      svg {
        margin-top: 0;
      }
      span {
        margin-left: 0.5em;
        flex: 11;
      }
    }
  }
}

.travel {
  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1em;
    align-items: center;
    justify-content: space-evenly;
  }
}

.main-card {
  display: flex;
  height: 100%;
  overflow: hidden;
  border-radius: 1em;
  position: relative;
  flex-direction: column;
  background-color: white;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 1em 3em rgba(8, 28, 70, 0.2);
  &:hover {
    box-shadow: 0 4px 10px rgba(8, 82, 231, 0.4);
  }
  &__title {
    margin: 0;
    padding: 0.8em;
    font-weight: 700;
    color: #3f51b5;
    text-align: center;
    margin-top: 1em;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  &__body {
    padding: 25px 1.2em;
    margin-bottom: 1em;
  }
  &__ul {
    list-style: none;
    padding-left: 2px;
    margin: 0;
    font-size: 0.9em;
    font-weight: 600;
    margin-left: 10px;
    margin-right: 10px;
  }
  &__li {
    display: flex;
    align-items: center;
    svg {
      margin-top: 0;
    }
    span {
      margin-left: 0.5em;
      flex: 11;
    }
  }
  &__link {
    padding: 0.8em 0;
    font-weight: 600;
    color: #0b51b9;
    display: flex;
    transition: 0.3s ease;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-top: 1px solid lighten($color: $gray, $amount: 43);
    &:hover {
      color: $mainOrange;
    }
  }
  &__description {
    text-align: center;
  }
  &--text {
    font-size: 1.1em;
    font-weight: 600;
    text-align: center;
    background-color: #e9ecef;
  }
}

.partner-card {
  &--img {
    width: 75%;
    margin: auto;
    padding: 1em;
  }
}

.card {
  &--grid {
    margin: 0;
    padding: 0;
    display: grid;
    list-style: none;
    // align-items: center;
    row-gap: var(--spacing-xl);
    column-gap: var(--spacing-md);
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
    grid-auto-flow: row;

    .list-item {
      margin: 0 1em;
      display: flex;
      color: #26282c;
      overflow: hidden;
      flex-direction: column;
      background-color: white;
      box-shadow: 0 0.5em 2.5em rgba(0, 0, 0, 0.1);
      border-radius: var(--spacing-xs);
      &__header {
        display: flex;
        color: lighten($bgPrimary, 45);
        background-color: #f0f3f7;
        justify-content: center;
        align-items: center;
        &--icon {
          padding: 1.5em;
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          &.icon {
            flex: none;
            border-radius: 50px;
          }
        }
      }
      &__body {
        padding: 1em;
        padding-bottom: 0;
        &--text {
          text-align: center;
          display: flex;
          margin: 0;
          span.item--text,
          .item--text {
            margin: auto;
          }
        }
      }
      &__footer {
        padding: 1em;
        text-align: center;
        .btn__link {
          margin: 0.5em;
          a {
            font-size: 0.8em;
            font-weight: 500;
            text-align: center;
            padding: 0.5em 1em;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .card--grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .card--grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
