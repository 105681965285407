/**
  *
  * HEADER STYLE
  *
**/

.courses {
  &--detail {
    &__main-title {
      font-size: 2em !important;
      margin-top: 1.5em !important;
      margin-bottom: 1.5em !important;
    }
  }
}

@media (min-width: 768px) {
  #coursesHeader {
    height: 100vh;
  }
}
