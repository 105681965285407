
.icover {
  padding: 0;
  overflow: hidden;
  &--image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}
