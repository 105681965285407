.bi {
  vertical-align: -0.125em;
  pointer-events: none;
  fill: currentColor;
}

.dropdown-toggle {
  outline: 0;
}

.nav-flush .nav-link {
  border-radius: 0;
}

.b-example-divider {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

a::first-letter {
  text-transform: uppercase;
  // &::first-letter {
  //   text-transform: uppercase;
  // }
}

p::first-letter {
  text-transform: uppercase;
  // &::first-letter {
  //   text-transform: uppercase;
  // }
}

.main-wrapper {
  left: 0;
  position: relative;
  transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
}

.sidebar {
  display: none;
  width: 275px;
  position: sticky;
  top: calc(71px + 1em);
  &-box {
    position: absolute;
  }
  &__button {
    display: flex;
    width: 100px;
    position: sticky;
    padding: 0.8em;
    // color: white;
    top: calc(80px);
    border-radius: 50px;
    // background-color: #d58003;
    &--show {
      width: 2em;
      height: 2em;
      margin-left: auto;
      cursor: pointer;
      &:hover {
        fill: $mainOrange;
      }
    }
  }

  & &--title {
    padding: 0.6em 0;
    padding-left: 1em;
  }

  &--title {
    text-transform: uppercase;
    font-size: 1rem;
    display: flex !important;
    color: #afafaf;
    font-weight: 700;
    align-items: center;
    padding-left: 1em;
    // background-color: $thinGrayDarken6;
    &-button {
      width: 3.5em;
      height: 3.5em;
      padding: 0.4em;
      margin-left: auto;
      cursor: pointer;
      &:hover {
        fill: $mainOrange;
      }
    }
  }

  &--mobile {
    left: -300px;
    z-index: 10; /* Stay on top */
    height: 100%; /* 100% Full-height */
    width: 300px;
    position: fixed; /* Stay in place */
    // background-color: #111; /* Black*/
    top: 68px;
    background-color: #f0f3f7;
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
    .text-fading {
      left: 0;
      right: 0;
      height: 50px;
      z-index: 1010;
      position: absolute;
      transition: 0.4s cubic-bezier(0, 0, 0.2, 1);
      &:hover {
        display: none;
      }
      &-top {
        top: 0;
        background-image: linear-gradient(180deg, #eee 30%, transparent);
      }
      &-bottom {
        bottom: 0;
        background-image: linear-gradient(0deg, white 30%, transparent);
      }
    }
  }
  .ul {
    &--container {
      position: relative;
      padding-top: 5px;
    }
  }
  &--container {
    > ul {
      position: relative;
      margin-left: 1.5rem;
      background-color: white;
      box-shadow: 0 0.8em 2em rgba(31, 31, 31, 0.25);
      max-height: calc(100vh - (71px * 2));
      > li > *:not(.collapsed) {
        padding-left: 0.4em !important;
      }
      &:first-child {
        text-transform: uppercase;
        // &::first-letter {
        //   text-transform: uppercase;
        // }
      }
    }
  }

  &__li {
    &--btn {
      width: 100%;
      display: inline-flex;
      align-items: center;
      font-size: 0.85em;
      padding: 0.2em 0.8em;
      text-align: justify;
      color: #4b4d50 !important;
      background-color: transparent;
      border: 0;
      &:hover {
        color: #3f51b5 !important;
        background-color: #e1e3ed;
      }
      &:hover,
      &:focus {
        font-weight: 600;
        color: darken($color: $thinGray, $amount: 60%);
      }
    }
  }
}
.intersecting {
  color: $mainOrange !important;
  &.collapse__li {
    display: flex;
    align-items: center;
    &--link {
      color: darken($color: $mainOrange, $amount: 40) !important;
      background-color: lighten($color: $mainOrange, $amount: 30);
      &:hover,
      &:focus {
        color: darken($color: $mainBlue, $amount: 20) !important;
      }
    }
  }
}

.btn-toggle {
  &::before {
    width: 1.25em;
    line-height: 0;
    text-align: center;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform 0.35s ease;
    transform-origin: 0.5em 50%;
  }

  &[aria-expanded='true'] {
    color: $primary;
    &::before {
      transform: rotate(90deg);
    }
  }

  &-nav a {
    font-size: 0.85em;
    padding-left: 15px;
    margin-left: 1em;
    color: $darkGray !important;
    &:hover {
      background-color: lighten($color: $mainBlue, $amount: 36);
    }
    &:hover,
    &:focus {
      color: darken($color: $mainBlue, $amount: 20);
    }
  }

  &[aria-expanded='false'] {
    &:focus {
      font-weight: 400;
    }
  }
}

.scrollarea {
  overflow-y: auto;
}

.fw-semibold {
  font-weight: 600;
}
.lh-tight {
  line-height: 1.25;
}

@media (min-width: 992px) {
  .sidebar {
    display: block;
    &--mobile {
      display: none;
    }

    .text-fading {
      display: none;
    }

    &__button {
      display: none;
    }

    &--title {
      &-button {
        display: none;
      }
    }
    &--container {
      > ul {
        margin-left: auto !important;
        max-height: calc(100vh - (71px + 8rem));
      }
    }
  }
}
