@media (min-width: 576px) {
}

@media (min-width: 800px) {
}

@media (max-width: 1299px) {
}

@media (max-width: 1199px) {
}

@media (max-width: 1099px) {
}

@media (min-width: 992px) {
 
}

@media (max-width: 899px) {
}

@media (max-width: 799px) {
  .shadow-lg-r {
    box-shadow: 0.1rem 0 0.3rem rgba(0, 0, 0, 0.3) !important;
  }

  .menuButon {
    display: flex;
  }
  #close_button {
    display: flex;
  }
}

@media (max-width: 768px) {
  .header {
    &__body {
      &--title {
        &-h1 {
          font-size: 3em;
        }
      }
      &--subtile {
        font-size: 1.3em;
      }
      &--description {
        &-text {
          font-size: 1.1em;
        }
      }
    }
  }
}

@media (max-width: 658px) {
}

@media (max-width: 575.99px) {
}

@media (max-width: 460px) {
}

@media (max-width: 360px) {
}
