@import '../components/homeHeader';

.home--about {
  .card--grid {
    column-gap: var(--spacing-xs);
  }
  &-container {
    .home--about-item {
      display: flex;
      flex-direction: column;
      padding: 1em;
      text-align: center;
      transition: 0.4s ease;
      background-color: white;
      box-shadow: 0 1px 0.4em rgba(32, 32, 32, 0.2);
      &:hover {
        background-color: #f9f8f7;
        box-shadow: 2px 8px 2em rgba(32, 32, 32, 0.2);
      }
      .about__img {
        // display: none;
        &--img {
          max-height: 100px;
        }
        #aboutLogo {
          height: 80%;
        }
      }
      .about__body {
        &--title {
          font-size: 1.1em;
          padding: 0.4em 0;
          margin: 0;
          margin-top: 1em;
          margin-bottom: 0.5em;
          text-transform: uppercase;
          font-family: 'Edu SA Beginner';
          font-weight: 700;
        }
        &--text {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .about__btn {
        flex: 1;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        &--link {
          padding: 15px;
          font-size: 1em !important;
          &:hover {
            color: lighten($mainOrange, 40%);
            background-color: darken($mainOrange, 10%);
            border-color: darken($mainOrange, 10%);
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 576px) {
  .home--about {
    &-container {
      .home--about-item {
        .about__body {
          &--title {
            font-size: 1.2em;
          }
        }
        .about__btn {
          &--link {
            padding: 12px 20px;
            font-size: 1.1em !important;
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .home--about {
    .card--grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (min-width: 992px) {
  // .home--about {
  //   .card--grid {
  //     grid-template-columns: repeat(3, 1fr);
  //   }
  // }
}

@media (min-width: 1200px) {
  .home--about {
    .card--grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
