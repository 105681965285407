@import '../components/sidebar';

.scroll-down {
  &-box {
    position: absolute;
    bottom: 25px;
  }
  &-btn {
    cursor: pointer;
    animation: mooveUpDown 0.3s ease-in-out infinite;
    &:hover {
      animation-play-state: paused;
    }
  }
}

.arret {
  animation-play-state: paused;
}

.fl-uppercase {
  display: block !important;
  & &::first-letter {
    text-transform: uppercase;
  }
}

.btn {
  &-reset {
    margin-left: 15px;
  }
}

[class^='link__btn'] {
  padding: 1em 1.4em;
  font-size: 1.1em;
  transition: 0.4s ease;
  /* text-transform: uppercase; */
  border-radius: 16px;
  display: inline-block;
}

.link__btn {
  &--primary {
    color: lighten($primary, 40%);
    background-image: linear-gradient(90deg, $bgPrimary 20%, transparent);
    background-color: $primary;
    &:hover {
      color: white;
      background-image: linear-gradient(90deg, $primary, transparent);
    }
  }
  &--secondary {
    color: lighten($mainOrange, 43%);
    background-image: linear-gradient(90deg, $bgSecondary 20%, transparent);
    background-color: #ffa51f;
    &:hover {
      color: white;
      background-color: $bgSecondary;
    }
  }
  &--tertiary {
    color: lighten($tertiary, 43%);
    background-image: linear-gradient(
      90deg,
      darken($tertiary, 10%),
      transparent
    );
    background-color: lighten($tertiary, 10%);
    &:hover {
      color: lighten($tertiary, 50%);
      background-color: darken($tertiary, 10%);
    }
  }
  &--white {
    color: $darkGray;
    font-weight: 600;
    font-size: 0.9em;
    background-image: linear-gradient(90deg, $mainOrangeLight 20%, transparent);
    background-color: white;
    &:hover {
      color: $sfzOrange;
      background-color: $mainOrangeLight;
    }
  }
}

.bg--primary {
  padding: 1em;
  color: white;
  background-color: $bgPrimary !important;
}

.bg--secondary {
  padding: 1em;
  color: white;
  background-color: $bgSecondary !important;
}

.square {
  padding-bottom: 100%;
  z-index: 2;
}

[class^='type'] {
  display: inline-block;
  // letter-spacing: 0.1em;
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  border-right: solid 3px $mainBlue;
}

.type-service {
  animation: typeService 2s steps(14) 1s forwards, blink 1s steps(1) infinite;
}

.type-contact {
  animation: typeContact 2s steps(17) 1s forwards, blink 1s steps(1) infinite;
}

.fa-spinner-third {
  animation: rotate 0.7s cubic-bezier(0.2, 0.52, 0.84, 0.54) infinite;
}

.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: none;
  opacity: 0.8;
  position: absolute;
  background-color: #fff;
}

/** ==============================
*** ========= KEY FRAMES =========
*** ============================*/

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes mooveUpDown {
  from {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes typeService {
  to {
    width: 13.5ch;
  }
}

@keyframes typeContact {
  to {
    width: 16.2ch;
  }
}

@keyframes blink {
  from {
    border-color: transparent;
  }
  50% {
    border-color: $mainBlue;
  }
  to {
    border-color: transparent;
  }
}

.social {
  display: inline-flex;
  align-items: center;
  &__link {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  &__icon {
    width: 2em;
  }
}

@media (min-width: 768px) {
  // .card--grid {
  //   grid-template-columns: repeat(2, 1fr);
  //   grid-template-rows: 2fr;
  // }

  .page-header {
    height: calc(100vh);
  }

  // .page-header {
  //   &__body {
  //     &--title {
  //       margin-top: 0.5em;
  //     }
  //   }
  // }
}

.text-highlighted {
  padding: 1.5em;
  font-size: 1.2em;
  margin-top: 2em;
  margin-bottom: 1.5em;
  font-weight: 400 !important;
  border: 1px solid #fafcff;
  box-shadow: 0 0.6em 2em #01014724;
  background-color: #f0f3f7;
}

strong {
  color: darken($primary, 20);
}
