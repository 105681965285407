$mainOrange: #fc9803;
$sfzOrange: #f97306;
$sfzYellow: #ffb600;
$sfzYellowLight: #eee8d9;
$mainOrangeLight: #f4eee1;
$mainBlue: #0d6efd;
$mainBlueLight: #e4deff;
$lightGray: #e0dee9;
$backgroundLight: #eff4f8;
$lineColor: #62646a;
$gray: #797d85;
$darkGray: #26282c;
$thinGray: #dfe0e1;
$blackBlue: #191b24;
$sfzlemon: #c9e501;
$blackBlueDarken6: darken($blackBlue, 6);
$blackBlueLighten6: lighten($blackBlue, 6);
$thinGrayDarken6: darken(
  $color: $thinGray,
  $amount: 6%,
);
$tertiary: #797d85;
$footerTitleColorDark: #bbdfff;
$mainBlue-transparent: rgba(13, 109, 253, 0.6);

$dragInOut: 0.72, -0.41, 0, 1.26;
$dragOut: 0.42, 0.41, 0, 1.13;

$bgPrimary: #024ebe;
$bgSecondary: #f19000;
$bodyBg: #f0f3f7;

$primary: $mainBlue;
$secondary: $mainOrange;
$danger: #f03c05;
$dark-blue: darken($primary, 30%);

$enable-smooth-scroll: false;

// Create your own map
$custom-colors: (
  'szblue': $primary,
  'szorange': $mainOrange,
);
