#clonedTopbar {
  background-color: #F4EEE1 !important;
}

#servicesHeader {
  background-image: url('../../assets/img/servicesheaderbg.png');
  background-color: #F4EEE1 !important;
}

@media (min-width: 768px) {
  #servicesHeader {
    // height: calc(100vh - 81px);

    .services__header--container {
      margin: auto;
    }

    // .header__body {
    //   flex: 2;
    // }

    .header__img {
      img {
        width: 100%;
      }
    }
  }
}
