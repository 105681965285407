#clonedTopbar {
  background-color: #f4f1df !important;
}

#aboutusHeader {
  background-image: url('../../assets/img/servicesheaderbg.png');
  background-color: #f4f1df !important;
}

.about__img {
  &--img {
    max-height: 70px;
  }
}

.divider {
  height: 5px;
  background-color: $bodyBg;
}

.card-item {
  position: relative;
  background-color: white;
  padding: 1em;
  text-align: center;
  border-top: 4px solid #0d6efd;
  box-shadow: 0 0.3em 2em #00000010;
  &__title {
    position: absolute;
    top: -1.2em;
    width: 75%;
    left: 12%;
    background-color: white;
    box-shadow: 0 0.2em 0.5em #0000001d;
    padding: 0.5em;
    border-radius: 50px;
    overflow: hidden;
    text-align: center;
    &--text {
      margin: 0;
      padding: 0;
      font-size: 1.1em;
      font-weight: 700;
    }
  }
  &__img {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
}

.about--addresses {
  display: grid;
  margin: 0.8em;
  // padding: 0 15px;
  margin-top: 1em;
  margin-bottom: 1em;
  grid-auto-flow: row;
  background-color: white;
  grid-gap: var(--spacing-lg);
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
  grid-template-columns: 1fr;
  .address {
    padding: 1em;
    &__item {
      display: flex;
      margin: 0.4em 0;
      align-items: center;
      &--title {
        padding: 5px 1em;
        background-color: #5524ab;
        min-width: 130px;
        text-align: center;
        font-weight: 600;
        color: white;
      }
      &--text {
        margin-left: 0.5em;
        padding: 0.4em;
      }
    }
  }
}

.team {
  &-container {
    display: grid;
    row-gap: var(--spacing-4xl);
    column-gap: 8%;
    margin-top: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
    align-items: start;
  }
  &-header {
    &-picture {
      max-width: 400px;
      margin: auto;
      border-radius: 30px;
      overflow: hidden;
      // border: 4px solid #ffffff;
      box-shadow: 2px 2px 2px 4px #ffffffe3, 0.2em 1em 2em #00000026;
    }
  }
  &-card {
    border: none;
    display: flex;
    position: relative;
    word-wrap: break-word;
    background-color: white;
    background-clip: border-box;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 2px 3px rgba(8, 28, 70, 0.25);
    &:hover {
      box-shadow: 0 1px 12px rgba(8, 82, 231, 0.35);
    }
    .member {
      &-name {
        text-align: center;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        .firstname {
          text-transform: capitalize;
        }
        .surename {
          text-transform: uppercase;
        }
      }
      &-info {
        font-size: 0.875em;
      }
      &-picture {
        display: none;
        img {
          color: #ffffff;
        }
        &.mobile {
          display: block;
        }
      }
      &-function {
        margin: 0;
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 0.5em;
      }
    }

    &__header {
      flex-basis: 30%;
    }
    &__body {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &--title {
        padding: 0.4em 0;
        font-weight: 700;
        font-size: 1.2em;
        margin: 0;
      }
    }
    &__footer {
      &--link {
        padding: 0.4em;
        font-size: 0.95em;
        font-weight: 600;
        border: 1px solid;
        &:hover {
          color: $mainOrange;
        }
      }
    }
    &--text {
      font-size: 1.1em;
      font-weight: 600;
      text-align: center;
      background-color: #e9ecef;
    }
    &__ul {
      margin: 0;
      padding: 0;
      list-style: none;
      font-size: 0.9em;
      font-weight: 600;
    }
    &__li {
      display: flex;
      align-items: center;
      padding-left: 1em;
      padding-right: 1em;
      svg {
        margin-top: 0;
      }
      span {
        margin-left: 0.5em;
        flex: 11;
      }
    }
  }
}

@media (min-width: 768px) {
  #aboutPartners {
    .card--grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .team {
    &-container {
      grid-template-columns: repeat(1, 1fr);
      .member {
        &-name {
          text-align: start;
        }
        &-picture {
          display: block;
          &.mobile {
            display: none;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .team {
    &-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .divider {
    height: auto;
  }
  .about--addresses {
    grid-template-columns: 1fr 5px 1fr;
  }
}
