#page__header {
  min-height: 650px;
  height: 100vh;
  .carousel {
    height: 100%;
    &-inner {
      height: 100%;
    }
    &-item {
      height: 100%;
      &-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
    .item-image {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      overflow: hidden;
      .img__blendmode {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: $dark-blue;
        background: linear-gradient(
          0deg,
          rgba(20, 9, 65, 0.8),
          rgba(15, 7, 47, 0.6),
          rgba(20, 9, 65, 0.8)
        );
      }
    }
    .item {
      &__body {
        &--title {
          color: white;
          font-size: 3em;
          font-family: 'Edu SA Beginner';
          font-weight: 700;
          margin: 25px;
          margin-top: 1.5em;
          text-align: center;
        }
      }
      &__caption {
        color: #dbd8d4;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        &--title {
          font-size: 1.5em;
          font-weight: 600;
          color: white;
        }
        &--description {
          font-size: 1.1em;
          font-style: italic;
        }
      }
    }
    .caption__btn {
      padding: 1.3em 0;
      & > * {
        font-weight: 600;
        padding: 1em;
        border-radius: 2em;
        margin: 1em;
        text-transform: uppercase;
      }
      &--primary {
        &:hover {
          background-color: darken($primary, 10%);
          border-color: darken($primary, 10%);
        }
      }
      &--secondary {
        &:hover {
          background-color: darken($mainOrange, 10%);
          border-color: darken($mainOrange, 10%);
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #page__header {
    // height: calc(100vh - 0px);
    .carousel {
      .item {
        &__caption {
          margin-top: 25px;
          margin-bottom: 25px;
          max-width: 700px;
          &--title {
            font-size: 2em;
            font-weight: 600;
          }
          &--description {
            strong {
              color: #e2dcfb;
            }
          }
        }
      }
    }
  }
}
