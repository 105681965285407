.topbar {
  /* height: 85px; */
  top: 0;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  /* border-bottom: 1px solid ${appColors.lineColor}; */
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.nav--link {
  outline: none;
  color: $darkGray !important;
  font-size: 1rem;
  &:hover {
    color: $gray;
    font-weight: 600;
    border-bottom: 3px solid;
    // background-color: $backgroundLight;
  }
  &:active {
    background-color: #fce5bb;
    color: $mainOrange !important;
  }
  &.active {
    color: $mainOrange !important;
  }
  &.disabled {
    color: #cfc0b6 !important;
  }
}

.home-nav {
  &--link {
    color: $mainBlueLight !important;
    &:hover {
      color: white !important;
    }
    &.disabled {
      color: #8b837d !important;
    }
  }
}
