.footer {
  font-size: 15px;
  color: #b0b3c2;
  background-color: $blackBlue;
  &__item {
    margin-bottom: 3em;
  }
  // #footerAddresses {
  //   margin-left: auto;
  // }
  // #footerContact {
  //   min-width: 375px;
  // }
  &__title {
    margin-bottom: 1em;
    color: white;
    &--text {
      font-size: 1.5em;
      font-weight: 600;
      padding-bottom: 0.4em;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    &--underliner {
      width: 100px;
      height: 2px;
      background-color: $primary;
    }
  }
  &--description {
    margin-bottom: 1em;
  }
  .addresses {
    // background-color: lighten($color: $primary, $amount: 38);
    &__body {
      padding: 0.5em 0.8em;
      > * {
        margin: 5px 0;
      }
      .address {
        display: flex;
        align-items: center;
        &--icon {
          width: 2rem;
          height: 2rem;
          fill: $primary;
          min-width: 1.5rem;
        }
        &--text {
          margin-left: 10px;
        }
      }
    }
    &__title {
      font-size: 1.1em;
      font-weight: 600;
      padding: 0.4em 0.8em 0em;
      color: white;
      // background-color: $blackBlueDarken6;
    }
  }
  .form__group {
    display: flex;
    margin-bottom: 1em;
    flex-direction: column;
    &--textarea {
      min-height: 140px !important;
    }
    &.form__button {
      display: block;
    }
  }
  .footer__form--control {
    color: white !important;
    border-color: $blackBlueLighten6;
    background-color: $blackBlueLighten6;
    &:focus {
      border-color: $primary;
    }
  }
  .copyright--div {
    background-color: $blackBlueDarken6;
  }
}
