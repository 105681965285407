#clonedTopbar {
  background-color: #ebf6fc !important;
}

#contactsHeader {
  background-image: url('../../assets/img/contactsheaderbg.png');
  background-color: #ebf6fc !important;
}

#contactSection {
  .card--grid {
    grid-template-columns: 1fr !important;
  }
}

.popup {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  display: none;
  position: absolute;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.75);
  .fa-regular {
    font-size: 1.4em;
  }
  &--container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__button {
    display: flex;
    align-items: center;
    &--close {
      color: #878787;
      margin-left: auto;
      padding: 10px;
      margin-top: 0.4em;
      margin-right: 0.4em;
    }
  }
  &__message {
    gap: 1em;
    display: flex;
    color: white;
    font-weight: 600;
    padding: 0.4em 1em;
    border-radius: 30px;
    align-items: center;
    background-color: #00d346;
  }
}

.opened {
  display: flex !important;
}

.contact {
  &-box {
    &__form {
      padding: 24px;
      border-radius: 15px;
      position: relative;
      overflow: hidden;
      background-color: #fffefe;
      color: $darkGray;
      box-shadow: 2px 3px 2em #14151630;
    }
    &__aside {
      &--title {
        font-size: 1.25em;
        font-weight: 700;
      }
    }
  }
}

.social-network {
  &--elt {
    overflow: hidden;
    position: relative;
    border-radius: 14px;
    background-color: white;
    transition: 0.25s ease-in-out;
    box-shadow: 0px 8px 11px rgba(16, 16, 16, 0.15);
    &:hover {
      border-color: $mainOrange;
      background-color: #f0f2f5;
      box-shadow: 0 4px 0 $mainOrange;
      a {
        color: $mainOrange !important;
      }
      .elt--icon {
        background-color: #ffffff;
      }
    }

    .elt--icon {
      position: absolute;
      width: 100%;
      height: 75%;
      padding: 15px;
      display: flex;
      background-color: #f0f2f5;
      transition: 0.25s ease-in-out;
      * {
        margin: auto;
        height: 100%;
      }
    }

    a {
      display: block;
      padding: 16px;
      font-size: 1.3em;
      font-weight: bold;
      color: $darkGray;
      padding-top: 75%;
      text-align: center;
      padding-bottom: 8%;
    }
  }
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .social-network {
    .card--grid {
      grid-template-columns: repeat(2, 1fr) !important;
    }
    &--elt {
      .elt--icon {
        height: 70%;
      }
      a {
        padding-bottom: 10%;
      }
    }
  }
}

@media (min-width: '992px') {
  #contactSection {
    .card--grid {
      grid-template-columns: 2fr 1fr !important;
      justify-content: space-between;
      align-items: center;
    }

    .contact {
      &-box {
        &__form {
          order: 1;
        }
        &__aside {
          margin-left: 2.5em;
        }
      }
    }
  }

  .social-network {
    .card--grid {
      grid-template-columns: repeat(3, 1fr) !important;
    }
  }
}

@media (min-width: '1200px') {
  #contactSection {
    .card--grid {
      grid-template-columns: 35em 1fr !important;
    }
  }

  // .social-network {
  //   .card--grid {
  //     grid-template-columns: repeat(5, 1fr) !important;
  //   }
  // }
}
